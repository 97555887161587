body {
    .modal-content {
        box-shadow: 0 2px 8px rgba(33, 37, 41, 0.1);
        border-radius: 0;

        .close {
            padding: 1.3rem;
            opacity: 1;
        }
    }

    .modal-footer {
        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            color: $cezColorDimGray;
        }
    }

    .mat-dialog-container {
        box-shadow: 0 2px 8px rgba(33, 37, 41, 0.1);
        border-radius: 0;
    }

    .mat-dialog-title {
        padding: 24px 24px 0 24px;
        margin: -24px -24px 16px !important;
        color: black;
        min-width: 300px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
    }

    .mat-dialog-content {
        color: black;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    }
}
