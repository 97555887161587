@mixin hover-element() {
    border-color: $cezSuperLightGrey;
    background: $cezSuperLightGrey;
}

@mixin icon-override($icon) {
    background: none !important;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-indent: 0px !important;
    text-align: center;

    &:before {
        content: $icon;
    }
}

.ui-widget {
    font-family: $fontFamily;
    font-size: $fontSize;

    input, select, textarea, button {
        font-family: $fontFamily;
        font-size: $fontSize;
    }

    :active {
        outline: none;
    }
}

.ui-widget-content {
    border: $contentBorderWidth solid $contentBorderColor;
    background: $cezWhiteGrey;
    color: $contentTextColor;

    a {
        color: $contentTextColor;
    }
}

.ui-widget-header:not(.ui-paginator):not(.ui-multiselect-header):not(.ui-table-scrollable-header):not(.ui-treetable-scrollable-header) {
    background: $cezOrange;
    color: $headerTextColor;
    font-weight: $headerFontWeight;

    a {
        color: $headerTextColor;
    }
}

.ui-chkbox-box {
    height: 1.2em !important;
    width: 1.2em !important;
}

.ui-chkbox-box {
    background-color: $cezLightGrey !important;
    span.fa {
        color: #ffffff;
    }
}

.ui-chkbox-label {
    vertical-align: initial;
    margin-bottom: 0
}

th .ui-chkbox-box {
    background-color: $cezSuperLightGrey !important;
    span.fa {
        color: #000000;
    }
}

.ui-widget-overlay {
    background: $cezHighchartTextColor;
    opacity: .50;
    filter: Alpha(Opacity=50);
}

.ui-widget-header .ui-state-default,
.ui-widget-content .ui-state-default,
.ui-state-default {
    //background: $stateDefaultBgColor;
    color: $stateDefaultTextColor;

    a {
        color: $stateDefaultTextColor;
    }
}

.ui-widget-header .ui-state-active,
.ui-widget-content .ui-state-active,
.ui-state-active {
    //background: $stateActiveBgColor;
    color: $white;

    a {
        color: $white;
    }
}

.ui-widget-header .ui-state-highlight:not(.ui-sortable-column),
.ui-widget-content .ui-state-highlight,
.ui-state-highlight {
    border-color: $stateHighlightBorderColor;
    color: $stateHighlightTextColor;

    a {
        color: $stateHighlightTextColor;
    }
}

.ui-widget-header .ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-state-focus {
    border-color: $stateFocusBorderColor;
    background: $stateFocusBgColor;
    color: $stateFocusTextColor;

    a {
        color: $stateFocusTextColor;
    }
}

.ui-widget-header .ui-state-error,
.ui-widget-content .ui-state-error,
.ui-state-error {
    border-color: $stateErrorBorderColor;
    background: $stateErrorBgColor;
    color: $stateErrorTextColor;

    a {
        color: $stateErrorTextColor;
    }
}

.ui-state-disabled,
.ui-widget:disabled {
    opacity: $disabledOpacity;
    filter: Alpha(Opacity=$disabledOpacity * 100);
    background-image: none;
    cursor: default !important;

    * {
        cursor: default !important;
    }
}

/* Forms */
.ui-inputtext {
    background: $inputBgColor;
    color: $inputTextColor;
}

.ui-inputtext:enabled {
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.ui-inputtext.ui-state-focus,
.ui-inputtext:focus {
    outline: 0 none;
    border-color: $stateFocusBorderColor;
    -moz-box-shadow: 0px 0px 5px $stateFocusBorderColor;
    -webkit-box-shadow: 0px 0px 5px $stateFocusBorderColor;
    box-shadow: 0px 0px 5px $stateFocusBorderColor;
}

span.ui-autocomplete {
    display: inline-flex;
    width: 100%;
}

button.ui-autocomplete-dropdown {
    height: 34px !important;
    width: 32px;
    display: flex;
    box-shadow: none;
    border: none;
}

input.ui-autocomplete-input {
    display: flex;
    flex: 1 1 auto;
}

.ui-autocomplete {
    .ui-autocomplete-multiple-container:not(.ui-state-disabled) {
        &.ui-state-focus {
            border-color: $stateFocusBorderColor;
        }
    }
}

.ui-chips {
    > ul:not(.ui-state-disabled) {
        &:hover {
            border-color: $stateHoverBorderColor;
        }

        &.ui-state-focus {
            border-color: $stateFocusBorderColor;
        }
    }
}

.ui-button:focus,
.ui-button:enabled:hover {
    outline: 0 none;
    @include hover-element();
}

.ui-button:enabled:active {
    border-color: $stateActiveBorderColor;
    background: $stateActiveBgColor;
    color: $white;
}

.ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
    @include hover-element();
}

.ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
    @include hover-element();
}

.ui-dropdown:not(.ui-state-disabled):hover {
    @include hover-element();
}

.ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):hover {
    @include hover-element();
}

.ui-listbox {
    .ui-listbox-header {
        .ui-listbox-filter-container {
            .fa {
                color: $inputTextColor;
            }
        }
    }

    &:not(.ui-state-disabled) {
        .ui-listbox-item:not(.ui-state-highlight):hover {
            @include hover-element();
        }
    }
}

//.ui-multiselect:not(.ui-state-disabled):hover {
//    @include hover-element();
//}

.ui-multiselect-panel .ui-multiselect-item:not(.ui-state-highlight):hover {
    @include hover-element();
}

.ui-multiselect-panel .ui-multiselect-close {
    color: $headerIconTextColor;
}

.ui-multiselect-panel .ui-multiselect-filter-container .fa {
    color: $inputTextColor;
}

.ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:hover {
    @include hover-element();
}

.ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:active {
    border-color: $stateActiveBorderColor;
    background: $stateActiveBgColor;
    color: $white;
}

.ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
    @include hover-element();
}

.ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
    @include hover-element();
}

.ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover {
    @include hover-element();
}

.ui-datatable {
    .ui-rowgroup-header a {
        color: $headerTextColor;
    }

    .ui-sortable-column:not(.ui-state-active):hover {
        background: $stateHoverBgColor;
        color: $stateHoverTextColor;
    }

    .ui-row-toggler {
        color: $contentTextColor;
    }

    tbody.ui-datatable-hoverable-rows {
        > tr.ui-widget-content:not(.ui-state-highlight):hover {
            cursor: pointer;
            background: $stateHoverBgColor;
            color: $stateHoverTextColor;
        }
    }
}

.ui-orderlist {
    .ui-orderlist-item:not(.ui-state-highlight):hover {
        @include hover-element();
    }
}

.ui-picklist {
    .ui-picklist-item:not(.ui-state-highlight):hover {
        @include hover-element();
    }
}

.ui-tree {
    width: 100%;
    .ui-treenode-content.ui-treenode-selectable {
        .ui-treenode-label:not(.ui-state-highlight):hover {
            @include hover-element();
        }
    }

    &.ui-tree-horizontal {
        .ui-treenode-content.ui-treenode-selectable {
            .ui-treenode-label:not(.ui-state-highlight):hover {
                background-color: inherit;
                color: inherit;
            }

            &:not(.ui-state-highlight):hover {
                @include hover-element();
            }
        }
    }
}

.ui-treetable {
    .ui-treetable-row.ui-treetable-row-selectable:not(.ui-state-highlight):hover {
        background: $stateHoverBgColor;
        color: $stateHoverTextColor;
    }
}

.ui-fieldset {
    &.ui-fieldset-toggleable {
        .ui-fieldset-legend:hover {
            @include hover-element();
        }
    }
}

.ui-panel {
    .ui-panel-titlebar {
        .ui-panel-titlebar-icon:hover {
            @include hover-element();
        }
    }
}

.ui-tabview {
    .ui-tabview-nav {
        li {
            &:not(.ui-state-active):not(.ui-state-disabled):hover {
                @include hover-element();
            }
        }
    }
}

.ui-dialog {
    .ui-dialog-titlebar-icon {
        color: $headerTextColor;

        &:hover {
            @include hover-element();
        }
    }
}

.ui-overlaypanel {
    .ui-overlaypanel-close:hover {
        @include hover-element();
    }
}

.ui-inplace {
    .ui-inplace-display:hover {
        @include hover-element();
    }
}

.ui-breadcrumb {
    a {
        color: $headerTextColor;
    }
}

.ui-menu {
    .ui-menuitem {
        .ui-menuitem-link {
            color: $contentTextColor;

            &:hover {
                @include hover-element();
                border-color: transparent;
            }
        }

        &.ui-menuitem-active {
            > .ui-menuitem-link {
                @include hover-element();
                border-color: transparent;
            }
        }
    }
}

.ui-tabmenu {
    .ui-tabmenu-nav {
        li:not(.ui-state-active):hover {
            @include hover-element();
        }
    }
}

.ui-steps {
    .ui-steps-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
        @include hover-element();
    }
}

.ui-panelmenu {
    .ui-panelmenu-header {
        &:not(.ui-state-active):hover {
            @include hover-element();

            a {
                color: $stateHoverTextColor;
            }
        }

        &.ui-state-active {
            a {
                color: $white;
            }
        }
    }

    .ui-panelmenu-content {
        .ui-menuitem-link {
            color: $contentTextColor;

            &:hover {
                @include hover-element();
                border-color: transparent;
            }
        }
    }
}

.ui-datepicker {
    .ui-datepicker-header {
        a {
            color: $headerTextColor;

            &:hover {
                @include hover-element();
            }
        }
    }

    .ui-datepicker-calendar {
        td:not(.ui-state-disabled) {
            a:hover {
                @include hover-element();
            }
        }
    }
}

.fc {
    .fc-toolbar {
        .fc-prev-button {
            .ui-icon-circle-triangle-w {
                margin-top: .3em;
                @include icon_override("\f053");
            }
        }

        .fc-next-button {
            .ui-icon-circle-triangle-e {
                margin-top: .3em;
                @include icon_override("\f054");
            }
        }
    }
}

.ui-rating {
    a {
        color: $inputTextColor;
    }
}

/* Validation */
.ui-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
p-calendar.ng-dirty.ng-invalid > .ui-inputtext,
p-chips.ng-dirty.ng-invalid > .ui-inputtext,
p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
p-listbox.ng-dirty.ng-invalid .ui-inputtext,
p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
p-selectbutton.ng-dirty.ng-invalid .ui-button,
p-togglebutton.ng-dirty.ng-invalid .ui-button {
    border-bottom-color: $invalidInputBorderColor;
}

/* Cornering */
.ui-corner-tl {
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
}

.ui-corner-tr {
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
}

.ui-corner-bl {
    -moz-border-radius-bottomleft: $borderRadius;
    -webkit-border-bottom-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

.ui-corner-br {
    -moz-border-radius-bottomright: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

.ui-corner-top {
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
}

.ui-corner-bottom {
    -moz-border-radius-bottomleft: $borderRadius;
    -webkit-border-bottom-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
    -moz-border-radius-bottomright: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

.ui-corner-right {
    -moz-border-radius-topright: $borderRadius;
    -webkit-border-top-right-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    -moz-border-radius-bottomright: $borderRadius;
    -webkit-border-bottom-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}

.ui-corner-left {
    -moz-border-radius-topleft: $borderRadius;
    -webkit-border-top-left-radius: $borderRadius;
    border-top-left-radius: $borderRadius;
    -moz-border-radius-bottomleft: $borderRadius;
    -webkit-border-bottom-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
}

.ui-corner-all {
    -moz-border-radius: $borderRadius;
    -webkit-border-radius: $borderRadius;
    border-radius: $borderRadius;
}

p-checkbox {
    gap: 0.5rem;
}

.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #D8DEE9;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: lightgrey;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box .p-icon {
  width: 14px;
  height: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: gray;
  background: gray;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: lightgrey;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #C0D0E0;
  border-color: lightgrey;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: lightgrey;
  background: lightgrey;
  color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: lightgrey;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: lightgrey;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: lightgrey;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: lightgrey;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: lightgrey;
}

.p-checkbox-label {
    margin: 0;
    line-height: unset;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 2px 0;
  padding: 0.5rem 0.75rem;
  border: 0 none;
  color: #334155;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 4px;
}

.p-component, .p-component * {
  box-sizing: border-box;
}
.p-column-filter-overlay-menu {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.p-column-filter-overlay {
  background: #ffffff;
  color: $cezOrange;
  border: 1px solid $cezOrange;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;
}

.p-fluid .p-dropdown {
  display: flex;
}

.p-dropdown {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}


  .p-column-filter-row .p-column-filter-menu-button,
  .p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
  }

  .p-column-filter-menu-button {
    width: 1.75rem;
    height: 1.75rem;
    color: $cezOrange;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-column-filter-menu-button:hover {
    color: #475569;
    border-color: transparent;
    background: #f1f5f9;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: #f1f5f9;
    color: #475569;
  }
  .p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #EFF6FF;
    color: $cezOrange;
  }
  .p-column-filter-menu-button:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }

  .p-column-filter-clear-button {
    width: 1.75rem;
    height: 1.75rem;
    color: $cezOrange;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  }
  .p-column-filter-clear-button:hover {
    color: $cezOrange;
    border-color: transparent;
    background: #f1f5f9;
  }
  .p-column-filter-clear-button:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
  }

  .p-column-filter-overlay {
    background: #ffffff;
    color: $cezOrange;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    min-width: 12.5rem;
  }
  .p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.25rem 0.25rem;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 2px 0;
    padding: 0.5rem 0.75rem;
    border: 0 none;
    color: $cezOrange;
    background: transparent;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    border-radius: 4px;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:first-child {
    margin-top: 0;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: $cezOrange;
    background: #EFF6FF;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: $cezOrange;
    background: #f1f5f9;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: 0 none;
  }
  .p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid $cezOrange;
    margin: 2px 0;
  }

  .p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.5rem 0.5rem 0 0.5rem;
    border-bottom: 0 none;
    color: $cezOrange;
    background: #ffffff;
    margin: 0 0 0 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 0 1.125rem 1.125rem 1.125rem;
    border-bottom: 1px solid #e2e8f0;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
  }
  .p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0 1.125rem 1.125rem 1.125rem;
  }
  .p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 0 1.125rem 1.125rem 1.125rem;
  }

.p-dropdown {
  background: #ffffff;
  border: 1px solid #cbd5e1;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 6px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #94a3b8;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: -1px;
  box-shadow: none;
  border-color: #94a3b8;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: $cezOrange;
}
.p-dropdown .p-dropdown-label:focus, .p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: $cezOrange;
  width: 2.5rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: $cezOrange;
  right: 2.5rem;
}
.p-dropdown.p-invalid.p-component {
  border-color: #f87171;
}
.p-dropdown.p-variant-filled {
  background-color: #f8fafc;
}
.p-dropdown.p-variant-filled:enabled:hover {
  background-color: #f8fafc;
}
.p-dropdown.p-variant-filled:enabled:focus {
  background-color: #ffffff;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #334155;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 0.5rem 0 0.5rem;
  border-bottom: 0 none;
  color: #334155;
  background: #ffffff;
  margin: 0 0 0 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: $cezOrange;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.25rem 0.25rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 2px 0;
  padding: 0.5rem 0.75rem;
  border: 0 none;
  color: $cezOrange;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
  border-radius: 4px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
  margin-top: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $cezOrange;
  background: #EFF6FF;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background: $cezOrange;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  color: #0f172a;
  background: #e2e8f0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: $cezOrange;
  background: #f1f5f9;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.5rem 0.75rem;
  color: $cezOrange;
  background: #ffffff;
  font-weight: 600;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 0.75rem;
  color: $cezOrange;
  background: transparent;
}

.p-input-filled .p-dropdown {
  background: #f8fafc;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f8fafc;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #ffffff;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
  background-color: transparent;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #f87171;
}
