.ngb-dp-weekday {
    font-style: normal !important;
    font-weight: 700 !important;
    color: $cezColorDimGray !important;
}

.datepicker-template:hover {
    background-color: $cezOrange !important;
    color: $cezWhite !important;
}

.ngb-dp-navigation-chevron {
    color: $cezColorBlack !important;
}

ngb-datepicker {
  // datepicker old design
  .ngb-dp-header, .ngb-dp-weekdays {
    background-color: var(--light) !important;
  }
  .ngb-dp-weekdays {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  .form-select {
    background-color: #ffffff;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}
