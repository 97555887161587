@import 'src/content/scss/variables';

table {
  border-collapse: initial !important;
  border-spacing: 0;
}

.p-highlight {
  background-color: #fbcab3 !important;
}

.table {
  font-size: 14px;
  text-align: left;

  label {
    margin: 0;
    color: #898989;
  }

  th {
    vertical-align: middle !important;
  }

  // the layout on design plans can not be recreated
  .ui-sortable-column {
    position: relative;
    padding: 0.75rem !important;
    padding-right: 1rem !important;

    > p-sorticon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  td {
    padding: 0.625rem 0.75rem .5rem !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 15px;
  }
}

.ui-table-sort-icon {
  color: $cezColorDarkGunmetal !important;
}

.ui-table-thead {
  font-size: 14px;
}

.table-filters {
  th {
    padding: 0 !important;
    vertical-align: middle !important;

    .date-filter-padding {
      margin: 4px;
    }

    > .form-control {
      $_margin: 4px;

      display: inline-block;
      max-width: calc(100% - #{$_margin * 2});
      margin: $_margin;
    }
  }
}

.table-row {
  &--highlight {
    background-color: $lightGray;
  }

  &--details {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    font-style: italic;
    color: $cezColorDimGray;
  }
}


tbody tr:last-child {
  border-bottom: 1px solid #dee2e6;
}

.countdown-column {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.status-circle {
  $_size: 6px;

  width: $_size;
  height: $_size;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background-color: $cezWhite;
}

p-table {
  p-paginator {
    .p-paginator-element {
      margin-top: 16px !important;
      padding-left: 6px !important;
      padding-right: 6px !important;
    }

    .p-paginator-element:hover {
      background: #e6e6e6
    }

    .p-highlight {
      font-weight: bold;
      background: #e6e6e6
    }
  }

  tbody {
    .p-highlight {
      background-color: #fbcab3;
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
  }
}
