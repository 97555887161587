body {
    $_border-radius: 8px;

    .progress {
        position: relative;
        background-color: transparent;
        color: currentColor;
        border: 1px solid currentColor;
        border-radius: $_border-radius;
    }

    .progress-bar {
        border-color: currentColor;
        color: currentColor;
        background-color: $cezColorLinen;
        border-top-right-radius: $_border-radius;
        border-bottom-right-radius: $_border-radius;

        &.progressbar-label--orange::after {
            color: $cezOrange;
        }

        &::after {
            width: 100%;
            position: absolute;
            font-weight: 700;
            content: attr(aria-valuenow) '%';
        }

        &[data-progressbar-label]::after {
            content: attr(data-progressbar-label);
        }

        &.progress-bar--hide-label::after {
            content: '';
        }
    }

    .text-hover-underline:hover .progress-bar::after {
        text-decoration: underline;
    }
}
