@import "variables";
@import "theme";
@import "material";
@import "quill/dist/quill.snow";
@import "ui-upgrade/imports";


/* ==============================================================
Bootstrap tweaks
===============================================================*/

body, h1, h2, h3, h4 {
  font-weight: 300;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Error highlight on input fields */
.ng-invalid:not(jhi-filters):not(form):not(quill-editor):not(p-autocomplete):not(mat-form-field):not([matinput]):not([formgroupname]):not(cez-time-interval):not(mat-select):not(.mat-dialog-content),
quill-editor.ng-invalid > div.ql-toolbar,
p-autocomplete.ng-invalid input.ui-autocomplete-dd-input,
p-autocomplete[ng-reflect-model]:not([ng-reflect-model="[object Object]"]) input.ui-autocomplete-dd-input,
.ng-invalid-right {
  border-color: $cezColorLava;
}

/* remove clear button X from inputs only for IE10+ -> check https://qsjira.qualysoft.com/browse/CEZINT-311 */
input::-ms-clear {
  display: none;
}

.tooltip-text-size {
  font-size: 24px !important;
  background-color: #8ec5fc;
}

/* other generic styles */

.jh-card {
  padding: 0 1.5% 1.5%;
  margin-top: 0;
  border: none !important;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

/*FIXME this is to support grids in table class; */
table td[class*=col-], table th[class*=col-] {
  position: static;
  display: table-cell;
  float: none;
}

.footer {
  border-top: 1px solid rgba(0, 0, 0, .125);
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
  cursor: pointer;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts {
  .alert {
    text-overflow: ellipsis;

    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    .popover pre {
      font-size: 10px;
    }
  }

  .toast {
    position: fixed;
    width: 100%;

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    &.top {
      top: 55px;
    }

    &.bottom {
      bottom: 55px;
    }
  }
}

.alert button {
  margin-left: auto;
  opacity: 1 !important;
  color: currentColor !important;
}

.alert-danger {
  color: $cezColorLava !important;
  border-color: currentColor !important;
  background-color: $cezColorLinen2 !important;
}

.alert-warning {
  color: $cezOrange !important;
  border-color: currentColor !important;
  background-color: $cezColorLinen !important;
}

.alert-success {
  color: $cezColorDarkPastelGreen !important;
  border-color: currentColor !important;
  background-color: #e5f8ed !important;
}

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%;
  }
}

/* ==========================================================================
entity tables helpers
========================================================================== */

/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
@mixin no-padding($side) {
  @if $side == 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding("left");
@include no-padding("right");
@include no-padding("top");
@include no-padding("bottom");
@include no-padding("all");

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important;
}

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > {
  dd {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      margin-bottom: 15px;
    }

    dd {
      border-bottom: 1px solid #eee;
      padding-left: 180px;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav-item, .pagination, .carousel, .panel-title a {
  cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus, ngb-datepicker:focus {
  outline: none;
}

/* jhipster-needle-scss-add-main JHipster will add new css style */

*:focus {
  outline: none !important;
}

.mat-card {
  border-radius: 2px !important;
  background: $cezWhite;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 32px;
  box-shadow: $matCardBoxShadow !important;

  .no-linebreak {
    white-space: nowrap;
  }
}

.button-row button,
.button-row a {
  margin-right: 8px;
}

.mat-chip.mat-standard-chip {
  background-color: forestgreen;
  color: white;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: $cezWhiteGrey;
}

.cez-stepper {
  background-color: #e6e6e6;
}

.mat-raised-button.mat-primary[disabled] {
  opacity: 0.65;
  color: #fff;
  background-color: $cezOrange;
}

.mat-tooltip.largeFont {
  font-size: 14px;
  background-color: $cezOrange;
}

.mat-tooltip.largeFontMultiline {
  font-size: 14px;
  white-space: pre-line;
  background-color: $cezOrange;
}

.session-warning-popup .mat-dialog-container {
  max-width: 500px !important;
}

.invoice-popup .mat-dialog-container {
  width: 80vw !important;
}

.blot-formatter__proxy-image {
  z-index: 2000;
}

.blot-formatter__toolbar {
  display: none;
}

.news-container * {
  max-width: 100% !important;
  cursor: unset !important;
}

.permission-overlay-checkbox {
  height: 20px !important;
}

.unavailable-button {
  background-color: darkgray !important;
  color: lightgrey !important;
  border-color: darkgray !important;
}

.cdk-overlay-container {
  z-index: 2000;
}

// FIXME [serfezea] move these into _inputs.scss
ng-select {
  &.form-control {
    & .ng-dropdown-panel {
      opacity: 1;
      height: auto;
      left: 0;
      border-radius: 0;
      top: 35px;
      background-color: white;
      border: solid 1px lightgrey;
    }

    & .ng-option {
      &:hover {
        background-color: #3f94e9;
        color: white;
      }
    }

    & .ng-arrow {
      border-color: #000 transparent transparent;
      border-style: solid;
      border-width: 5px 3px 2.5px;
      top: -2px;
    }

    & .ng-spinner-loader {
      top: 2px;
    }
  }
}

.bg-cezorange {
  background-color: $cezOrange !important;
}

.centered-tabset > ul {
  justify-content: center !important;
}

.min-card-height {
  min-height: 460px;
  max-height: 100%;
}

.cursor--default {
  &,
  & > * {
    cursor: default !important;
  }
}

.cursor--pointer {
  cursor: pointer;
}

.text-hover-underline:hover {
  text-decoration: underline;
}

.delay-color-change {
  transition: background-color 0.8s !important;
}

.expire-highlight {
  color: white;
  background-color: $cezOrange;

  border-radius: 6px;
  padding: 3px 8px;
}

.bg-cezorange-light {
  background-color: $cezOrangeLight;
}

.bg-cezgreen {
  background-color: $cezColorDarkPastelGreen;
}

.vertical-align--middle {
  vertical-align: middle;
}

jhi-date-picker {
  display: flex;
  flex-direction: row; /* Items will be aligned in a row */
  gap: 10px; /* Optional: space between items */
}

jhi-date-picker.ng-invalid, jhi-date-picker.ng-valid {
  border-left: none !important;
}


ngx-loading.local-loader .backdrop {
  // local loaders should be below new-claim-dialog
  z-index: 500 !important;
}

jhi-pinned-coverage-transaction-bar {
  // pinned-coverage-transaction-bar should be above local loaders
  z-index: 600 !important;
}

.jh-navbar.navbar {
  // navbar with dropdown should be above local loaders and pinned-coverage-transaction-bar
  z-index: 700 !important;
}

ngb-modal-backdrop {
  z-index: 10 !important;
}

.dropdown-menu-admin-panel {
  margin-left: -200px !important;
}




.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  background-color: #fbcab3 !important;
  border-color: gray !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  background-color: #fbcab3 !important;
  border-color: gray !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked + div:after {
  left: 3px !important;
  width: 10px !important;
  height: 4px !important;
  border-width: 0 0 1px 1px !important;
}

.multiselect-item-checkbox input[type=checkbox] + div:before {
  border: 2px solid #D8DEE9 !important;
  background: #ffffff !important;
  color: lightgrey !important;
  border-radius: 4px !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}

.p-autocomplete {
  display: flex;
}

.p-autocomplete input {
  border: 1px solid #d9d9d9;
  padding: 8px;
  font-size: 14px;
  color: black;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
}


.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  color: black !important;
  padding: 0;
  margin: 0;
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.5rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 2.857rem;
}

.p-autocomplete:not(.p-disabled):hover .p-autocomplete-multiple-container {
  border-color: $cezColorGray;
}

.p-autocomplete:not(.p-disabled).p-focus .p-autocomplete-multiple-container {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px $cezColorGray;
  border-color: $cezColorGray;
}

.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.0rem 0.0rem;
  gap: 0.0rem;
  height: 40px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0rem 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.87);
  padding: 0;
  margin: 0;
  height: 40px;
  width: 200px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.5rem;
  background: $cezOrange;
  color: rgba(255, 255, 255, 0.87);
  border-radius: 16px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token.p-focus {
  background: $cezColorIron;
  color: rgba(255, 255, 255, 0.87);
}

.p-autocomplete.p-invalid.p-component > .p-inputtext {
  border-color: $cezOrange;
}

.p-autocomplete-panel {
  background: $cezColorIron;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid $cezColorIron;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: $cezColorBlack;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:first-child {
  margin-top: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: $cezColorBlack;
  background: $cezColorIron;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight.p-focus {
  background: $cezColorIron;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled).p-focus {
  color: rgba(255, 255, 255, 0.87);
  background: rgba(255, 255, 255, 0.09);
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(255, 255, 255, 0.87);
  background: rgba(255, 255, 255, 0.03);
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: rgba(255, 255, 255, 0.87);
  background: $cezColorIron;
  font-weight: 600;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.87);
  background: transparent;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: $cezOrange;
}

p-autocomplete.p-autocomplete-clearable .p-inputtext {
  padding-right: 2rem;
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
  color: rgba(255, 255, 255, 0.6);
  right: 0.5rem;
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd .p-autocomplete-clear-icon {
  color: rgba(255, 255, 255, 0.6);
  right: 2.857rem;
}

th .dropdown-menu {
    font-weight: normal;
}

