body .form-control {
    // FIXME [serfezea] this might override error states
    border: 1px solid $cezColorGray;

    &[disabled],
    &[readonly] {
        background-color: $cezColorAntiFlashWhite;
    }
}

.input-group {
    .input-group-prepend,
    .input-group-append {
         > .btn {
             border: 1px solid $cezColorGray;

             &,
             &:hover {
                 background-color: #fff;
             }
         }
    }

    .input-group-prepend > .btn {
        border-right: 0 none transparent;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .input-group-append > .btn {
        border-left: 0 none transparent;
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
}
