$fontFamily: 'Nimbus';
$fontSize: 1em;
$borderRadius: 0.25rem;
$disabledOpacity: 0.35;

//Header
$headerBorderWidth: 1px;
$headerBorderColor: #dddddd;
$cezPink: #FFE0D1;
$headerTextColor: #e9e9e9;
$headerFontWeight: normal;
$headerIconTextColor: #444444;
$cezLightGrey: #8c8c8c;
$cezSuperLightGrey: #e6e6e6;

//Content
$contentBorderWidth: 1px;
$contentBorderColor: #dddddd;
$cezWhiteGrey: #EFEFEF;
$contentTextColor: #333333;

//Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: #f24f00;
$stateDefaultBgColor: #f24f00;
$stateDefaultTextColor: #333333;

//Active State
$stateActiveBorderColor: #f5f5f5;
$stateActiveBgColor: #bf3e00;
$white: #ffffff;

//Highlight State
$stateHighlightBorderColor: #ffffff;
$cezRed: #a00c15;
$stateHighlightTextColor: #333333;

//Focus State
$stateFocusBorderColor: #c0c0c0;
$stateFocusBgColor: #eeeeee;
$stateFocusTextColor: #212121;

//Error State
$stateErrorBorderColor: #f44336;
$stateErrorBgColor: #f5554a;
$stateErrorTextColor: #cd0a0a;

//Hover State
$stateHoverBorderColor: #DF4900;
$stateHoverBgColor: #DF4900;
$stateHoverTextColor: #eeeeee;

//Forms
$inputBgColor: #f6f6f6;
$inputTextColor: #222222;
$invalidInputBorderColor: #f44336;

//Material card
$matCardBoxShadow: 0 2px 8px rgba(33, 37, 41, 0.1);
$navBarBoxShadow: 0 2px 12px rgba(33, 37, 41, 0.15);

$cezGreen: #0ca015;
$cezWhite: #FFFFFF;
$cezOrange: #f24f00;
$cezOrangeLight: #f79566;

// ui upgrade colors:
// @see https://www.color-name.com/hex
$cezColorDarkGunmetal: #292521;
$cezColorLava: #cd1919;
$cezColorSelectiveYellow: #ffb700;
$cezColorDarkPastelGreen: #00be4d;
$cezColorBlueCola: #008fdf;
$cezColorAmethyst: #9a56cf;
$cezColorManatee: #989ea2;
$cezColorLinen: #feede5;
$cezColorLinen2: #fae8e8;
$cezColorMacaroniAndCheese: #fab999;
$cezColorDimGray: #676C70;
$cezColorGray: #bcbebf;
$cezColorIron: #E9E9EA;
$cezDarkGray: #212529;
$cezColorBlack: #000;
$cezColorUnbleachedSilk: #fcdccc;
$cezColorLightSilver: #d6d8da;
$cezColorAntiFlashWhite: #f2f2f2;

$cezHighchartXAxis: #ccd6eb;
$cezHighchartTextColor: #666666;

$lightGray: #f8f8f9;
