.nav-tabs {
    $_item-padding: 1rem;
    $_line-height: 2px;
    border-bottom-width: $_line-height !important;

    .tabset--hidden-header > & {
        display: none !important;
    }

    .nav-item {
        margin-bottom: -$_line-height !important;

        // first element has no padding on the left side on the design
        &:first-child a.nav-link {
            padding-left: 0;

            &.active::before {
                width: calc(100% - #{ $_item-padding });
            }
        }
    }

    a.nav-link {
        position: relative;
        padding-left: $_item-padding;
        padding-right: $_item-padding;
        background-color: transparent !important;
        border: 0 none transparent !important;
        font-size: 16px;
        font-weight: 700;
        color: $cezColorDimGray;

        &:hover,
        &.active {
            color: $cezOrange;
        }

        &.active {
            cursor: default;

            &::before {
                position: absolute;
                bottom: 0;
                right: $_item-padding;
                width: calc(100% - #{ $_item-padding * 2 });
                height: $_line-height;
                background-color: currentColor;
                content: ' ';
            }
        }
    }
}

.tabset--hidden-header {
    & .tab-pane {
        padding-top: 0;
    }
}
