.mat-radio-button {
    .mat-radio-outer-circle {
        border-width: 1px;
        background-color: $cezWhite;
    }

    &.mat-primary:not(.mat-radio-disabled) {
        .mat-radio-outer-circle {
            border-color: $cezColorGray;
        }
    }
}
