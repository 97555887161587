.card-header-row {
    display: flex;

    &__text-container {
        flex: 1;
    }

    &__button-container {
        height: 0;
    }
}

.card-footer-row {
    display: flex;

    &__text-container {
        flex: 1;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
    }
}

.card-no-data-row {
    padding-top: 24px;
    padding-bottom: 24px;
    border-top: 1px solid $cezColorIron;

    &__text {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
    }

    &__button {
        transform: scale(0.4) !important;
        margin: -10px !important;
        cursor: default !important;
    }
}

.mat-card-min-height {
    .p-datatable-wrapper {
        min-height: 450px;
    }
}
