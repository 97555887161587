

.highcharts-tooltip {
}

.highcharts-tooltip .highcharts-tooltip-box {
  stroke-width: 1px;
}

.highcharts-centered {
  .highcharts-container {
    margin: auto;
  }
}

highcharts-chart {
  width: 100%;
  display: block;
  overflow: hidden;
}

.highcharts-xaxis .highcharts-axis-line {
  stroke: $cezHighchartXAxis; /* Change X-axis line color */
}

.highcharts-xaxis .highcharts-tick {
  stroke: $cezHighchartXAxis; /* Change X-axis tick color */
}


.highcharts-legend-item {
  font-weight: bold;
}

.highcharts-axis-title {
  color: $cezHighchartTextColor !important;
  fill: $cezHighchartTextColor !important;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif !important;
  font-size: 12px !important;
}

.highcharts-xaxis-labels text {
  cursor: default !important;
  font-size: 11px !important;
  color: $cezHighchartTextColor !important;
  fill: $cezHighchartTextColor !important;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif !important;

}

.highcharts-yaxis-labels text {
  cursor: default !important;
  font-size: 11px !important;
  color: $cezHighchartTextColor !important;
  fill: $cezHighchartTextColor !important;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif !important;

}

.highcharts-xaxis-labels text tspan{
  cursor: default !important;
  font-size: 11px !important;
  fill: $cezHighchartTextColor !important;
  color: $cezHighchartTextColor !important;
  font-family: 'Nimbus' !important;

}

.highcharts-yaxis-labels text tspan{
  cursor: default !important;
  font-size: 11px !important;
  fill: $cezHighchartTextColor !important;
  color: $cezHighchartTextColor !important;
  font-family: 'Nimbus' !important;

}
