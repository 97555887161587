.cez-label {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 2px 0;
    width: 106px;
    height: 24px;
    text-align: center;

    &--orange {
        background-color: $cezColorLinen;
        color: $cezOrange !important;
    }

    &--grey {
        background-color: $cezColorIron;
        color: $cezColorDimGray !important;
    }
}
