.btn,
[mat-toggle-button],
[mat-raised-button],
[mat-icon-button],
[mat-mini-fab],
[mat-fab] {
    border-radius: 4px !important;
    box-shadow: none !important;
}

[mat-icon-button],
[mat-mini-fab],
[mat-fab] {
    &.btn-sm {
        $_size: 32px;
        $_icon-size: 20px;

        width: $_size;
        height: $_size;

        .mat-button-wrapper {
            padding: 0;
            line-height: $_size;
        }

        .mat-icon {
            font-size: 18px;
        }

        &:not([disabled]):not([cez-color="dark"]):not(.cez-color--dark):hover {
            border-color: $stateHoverBorderColor !important;
            background-color: $stateHoverBgColor !important;
            color: $stateHoverTextColor !important;
        }
    }
}

// works on mat buttons too
.btn--bordered {
    box-shadow: 0 0 0 2px $cezOrange inset !important;
    background-color: transparent !important;
    border: 0 none transparent !important;

    .fa,
    .mat-icon {
        color: $cezColorDarkGunmetal;
    }

    &:not([disabled]):not(.card-no-data-row__button):hover {
        background-color: $cezOrange !important;
        color: $cezWhite;

        .fa,
        .mat-icon {
            color: $cezWhite;
        }
    }

    &[disabled] {
        box-shadow: 0 0 0 2px $cezColorGray inset !important;

        .fa,
        .mat-icon {
            color: $cezColorGray;
        }
    }
}

.cez-color--dark,
[cez-color="dark"] {
    &:not([disabled]) {
        background-color: $cezColorDarkGunmetal !important;
        color: $cezWhite;

        &:hover {
            border-color: $cezColorBlack !important;
            background-color: $cezColorBlack !important;
        }
    }

    &[disabled] {
        background-color: $cezColorIron !important;

        .fa,
        .mat-icon {
            color: $cezWhite;
        }
    }
}

table {
    [mat-icon-button],
    [mat-mini-fab] {
        &[color="primary"][disabled] {
            background-color: $cezColorLinen !important;

            .fa,
            .mat-icon {
                color: $cezColorMacaroniAndCheese;
            }
        }
    }
}

.button-toggle-group--icon {
    box-shadow: none !important;

    &.btn-sm .mat-button-toggle-label-content {
        $_size: 32px;
        min-width: $_size;
        line-height: $_size;
    }

    .mat-button-toggle {
        border-radius: 4px !important;

        + .mat-button-toggle {
            margin-left: 0.25rem;
        }
    }

    .mat-button-toggle-label-content {
        $_size: 36px;

        min-width: $_size;
        line-height: $_size;
        padding: 0;
    }

    .mat-button-toggle--dark {
        background-color: $cezColorIron;

        .fa,
        .mat-icon {
            color: $cezColorGray;
        }

        &:hover {
            background-color: $cezColorLightSilver !important;

            .fa,
            .mat-icon {
                color: $cezColorManatee;
            }
        }

        &.mat-button-toggle-checked {
            background-color: $cezColorDarkGunmetal;

            .fa,
            .mat-icon {
                color: $cezWhite;
            }

            &:hover {
                background-color: $cezColorBlack !important;
            }
        }
    }

    .mat-button-toggle--primary {
        background-color: $cezColorLinen !important;

        .fa,
        .mat-icon {
            color: $cezColorMacaroniAndCheese;
        }

        &:hover {
            background-color: $cezColorUnbleachedSilk !important;

            .fa,
            .mat-icon {
                color: $cezOrangeLight;
            }
        }

        &.mat-button-toggle-checked {
            background-color: $cezOrange !important;

            .fa,
            .mat-icon {
                color: $cezWhite;
            }

            &:hover {
                background-color: $stateHoverBgColor !important;
            }
        }
    }
}

.cez-btn-group {
    &__button {
        $_height: 32px;

        height: $_height;
        min-width: 42px;
        line-height: $_height;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border-radius: 4px;
        font-weight: 700 !important;
        font-size: 14px !important;
        background-color: $cezColorLinen;
        color: $cezOrange;

        &,
        &:focus {
            box-shadow: none !important;
        }

        &:not([disabled]):hover {
            border-color: $cezColorUnbleachedSilk !important;
            background-color: $cezColorUnbleachedSilk !important;
        }

        &--active {
            background-color: $cezOrange;
            color: $cezWhite;

            &:not([disabled]):hover {
                border-color: $stateHoverBorderColor !important;
                background-color: $stateHoverBgColor !important;
            }
        }
    }
}
